.search-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .search-input {
    width: 50%;
    max-width: 500px;
    border-radius: 20px; /* Make the search input round */
    margin-bottom: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .results-container {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    min-height: 100vh !important;
  }
  