.forgotPassword {
  padding: 15px;
  width: 50%;
  position: relative;
  top: 18rem;
  left: 50%;
  -ms-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border: 1px solid #6F6F6F;
  border-radius: 10px;
  background-color: white;
 

}
@media (max-width: 480px) {
  .forgotPassword {
    width: 100%;
    padding-left: 1rem;
  }
}

/* .footerForget{
   
  position: absolute;

  bottom: -12rem;

} */
