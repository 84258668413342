.decoration a {
  text-decoration: none;
}
@media (max-width: 575.98px) {
  .footer-body {
    height: auto;
    background-color: #f6f6f6;
    padding-left: 12px;
    margin-top: 40px;
  }
  .image-padding {
    padding-top: 32px;
  }
  .decoration {
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #000000;
  }
  .footer-content {
    padding-top: 29px;
  }
  .social-icons {
    padding-top: 25px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .footer-body {
    height: auto;
    background-color: #f6f6f6;
  }
  .image-padding {
    padding-top: 0px;
  }
  .decoration {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #000000;
  }
  .footer-content {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 29px;
    text-align: center;
  }
  .social-icons {
    padding-top: 30px;
  }

  .spacing-text {
    padding-top: 22px;
    display: 0;
    justify-content: 0;
  }
}

@media (min-width: 992px) {
  .footer-body {
    height: 248px;
    background-color: #f6f6f6;
  }
  .image-padding {
    padding-top: 0;
  }
  .decoration {
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #000000;
  }
  .footer-content {
    padding-left: 65px;
    padding-right: 65px;
    padding-top: 58px;
  }
  .social-icons {
    padding-top: 49px;
    display: 0;
    justify-content: 0;
  }
}
