@media (max-width: 575.98px) {
  .filter-block {
    width: 100%;
    height: auto;
    background: #ffffff;
    border-top: 1px solid #dcdcdc;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
  }
  .filter-inside-block {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .group-button {
    display: flex;
    gap: 10px;
    margin-top: auto;
  }
  .active-apply-btn {
    width: 100px;
    height: 40px;
    background: var(--list-item-color);
    border: 1px solid var(--list-item-color);
    border-radius: 4px;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .filter-block {
    width: 100%;
    height: 4.5vh;
    background: #ffffff;
    border-top: 1px solid #dcdcdc;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
  }
  .filter-inside-block {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .group-button {
    display: flex;
    gap: 10px;
  }
  .active-apply-btn {
    width: 100px;
    height: 40px;
    background: var(--list-item-color);
    border: 1px solid var(--list-item-color);
    border-radius: 4px;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}

@media (min-width: 992px) {
  .filter-block {
    width: 100%;
    height: 10vh;
    background: #ffffff;
    border-top: 1px solid #dcdcdc;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.12);
  }
  .filter-inside-block {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .group-button {
    display: flex;
    gap: 10px;
  }
  .active-apply-btn {
    width: 100px;
    height: 40px;
    background: var(--list-item-color);
    border: 1px solid var(--list-item-color);
    border-radius: 4px;
    color: #ffffff;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}
