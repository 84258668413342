@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .job-desc-status {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(132, 185, 223, 0.2);
    border-radius: 3px 3px 0px 0px;
    width: auto;
    height: auto;
    padding-top: 6px;
    padding-left: 40px;
  }
}

@media (min-width: 992px) {
  .job-desc-status {
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(132, 185, 223, 0.2);
    border-radius: 3px 3px 0px 0px;
    width: auto;
    height: auto;
    padding-top: 6px;
    padding-left: 40px;
    padding-bottom: 10px;
  }
}
