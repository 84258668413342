@media (max-width: 575.98px) {
  .size-reducer {
    width: 100%;
    margin-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
  }
  .size-reducer {
    width: 100%;
  }
  .newUser-form-text {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #444444;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .newuser-form-control {
    border-top: 0px;
    border-bottom: 1px solid black;
    border-left: 0px;
    border-right: 0px;
    box-shadow: none;
    background: #ffffff;
    color: black;
    margin-bottom: 10px;
  }
  .css-13cymwt-control {
    border-top: 0px !important;
    border-bottom: 1px solid black !important;
    border-left: 0px !important;
    border-right: 0px !important;
    box-shadow: none !important;
    background: #ffffff !important;
    color: black !important;
  }
  .css-1jqq78o-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #444444 !important;
  }
  .newuser-form-control::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }
  .previous-employer-list {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .previous-employer-box {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: #f5f5f5;
    border-radius: 4px;
    display: flex;

    gap: 10px;
    padding: 12px 16px;
  }

  .edit-button {
    display: flex;
    justify-content: end;
  }
  .filled-icons {
    color: var(--list-item-color) !important;
  }
  .addSkillBtn {
    background: var(--list-item-color) !important;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .newUser-form-text {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #444444;
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .newuser-form-control {
    border-top: 0px;
    border-bottom: 1px solid black;
    border-left: 0px;
    border-right: 0px;
    box-shadow: none;
    background: #ffffff;
    color: black;
  }
  .css-13cymwt-control {
    border-top: 0px !important;
    border-bottom: 1px solid black !important;
    border-left: 0px !important;
    border-right: 0px !important;
    box-shadow: none !important;
    background: #ffffff !important;
    color: black !important;
  }
  .css-1jqq78o-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #444444 !important;
  }
  .newuser-form-control::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }
  .previous-employer-list {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .previous-employer-box {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: #f5f5f5;
    border-radius: 4px;
    display: flex;

    gap: 10px;
    padding: 12px 16px;
  }
  .size-reducer {
    width: 700px;
  }
  .candidate-skill-section {
    background: #fdfdfd;
    border: 1px solid #dadada;
    border-radius: 4px;
  }
  .filter-box {
    width: 325px;
    height: auto;
    background: #ffffff;
    border-radius: 10px;
  }
  .filter {
    margin-left: 16px;
    margin-top: 20px;
  }
  .same-formating {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }
  .clear-all {
    margin-right: 16px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    color: #6e4dcd;
  }

  .filter-border {
    margin-top: 12px;
    border: 1px solid #efebf9;
    transform: rotate(0.06deg);
  }

  .filter-search-box {
    margin-top: 25px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .has-search-for-filter .form-control {
    padding-left: 2.375rem;
  }

  .has-search-for-filter .form-control-feedback-for-filter {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }

  .filter-skill {
    margin-top: 32px;
    height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .filter-searched-skill {
    margin-left: 14px;
  }
  .filter-experience {
    margin-top: 21px;
    margin-left: 16px;
  }
  .filter-option {
    margin-top: 12px;
  }
  .filter-button {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
  }
  .show-result {
    background: #6e4dcd;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    width: 101px;
    height: 30px;
  }
  .edit-button {
    display: flex;
    justify-content: end;
  }
  .updDel {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .filled-icons {
    color: var(--list-item-color) !important;
  }
  .addSkillBtn {
    background: var(--list-item-color) !important;
  }
}

@media (min-width: 992px) {
  .newUser-form-text {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #444444;
    margin-top: 60px;
    margin-bottom: 40px;
  }
  .newuser-form-control {
    border-top: 0px;
    border-bottom: 1px solid black;
    border-left: 0px;
    border-right: 0px;
    box-shadow: none;
    background: #ffffff;
    color: black;
  }
  .css-13cymwt-control {
    border-top: 0px !important;
    border-bottom: 1px solid black !important;
    border-left: 0px !important;
    border-right: 0px !important;
    box-shadow: none !important;
    background: #ffffff !important;
    color: black !important;
  }
  .css-1jqq78o-placeholder {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    color: #444444 !important;
  }
  .newuser-form-control::placeholder {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }
  .previous-employer-list {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .previous-employer-box {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    background: #f5f5f5;
    border-radius: 4px;
    display: flex;

    gap: 10px;
    padding: 12px 16px;
  }
  .size-reducer {
    width: 872px;
    margin-left: 0.05rem;
  }
  .candidate-skill-section {
    background: #fdfdfd;
    border: 1px solid #dadada;
    border-radius: 4px;
  }
  .filter-box {
    width: 325px;
    height: auto;
    background: #ffffff;
    border-radius: 10px;
  }
  .filter {
    margin-left: 16px;
    margin-top: 20px;
  }
  .same-formating {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }
  .clear-all {
    margin-right: 16px;
    margin-top: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    cursor: pointer;
    color: #6e4dcd;
  }

  .filter-border {
    margin-top: 12px;
    border: 1px solid #efebf9;
    transform: rotate(0.06deg);
  }

  .filter-search-box {
    margin-top: 25px;
    margin-left: 12px;
    margin-right: 12px;
  }

  .has-search-for-filter .form-control {
    padding-left: 2.375rem;
  }

  .has-search-for-filter .form-control-feedback-for-filter {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
  }

  .filter-skill {
    margin-top: 32px;
    height: 100px;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .filter-searched-skill {
    margin-left: 14px;
  }
  .filter-experience {
    margin-top: 21px;
    margin-left: 16px;
  }
  .filter-option {
    margin-top: 12px;
  }
  .filter-button {
    padding-top: 16px;
    padding-bottom: 16px;
    text-align: center;
  }
  .show-result {
    background: #6e4dcd;
    border-radius: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #ffffff;
    width: 101px;
    height: 30px;
  }
  .edit-button {
    display: flex;
    justify-content: end;
  }
  .updDel {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .filled-icons {
    color: var(--list-item-color) !important;
  }
  .addSkillBtn {
    background: var(--list-item-color) !important;
  }
}

button {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}
.on {
  color: var(--list-item-color);
}
.off {
  color: #ccc;
}


.descriptionBox{
  width: 69vw;
  margin-bottom: 5rem;
}

@media (max-width: 575.98px) {

  .descriptionBox{
    width: 100vw;
    margin-bottom: 2rem;
  }

}