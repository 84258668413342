.jobTitle{
    box-sizing: border-box;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(132, 185, 223, 0.2);
    border-radius: 3px 3px 0px 0px;
}
.jobTitleBox{
    display: block;
    padding: 2rem;
}

.jobDesc{
    display: block;
    margin: 2rem;
}

.descBox{

    border: 1px solid #DADADA;
    border-radius: 4px;
 
}
.btnJobApply{

width: 160px;
height: 40px;
left: 666px;
top: 320px;
}

.leftcontent{
    height: 100vh;
    overflow-y: scroll;    
    overflow-x: hidden;
    scroll-behavior: smooth;
  
}


.leftcontent::-webkit-scrollbar {
    width: 1px;
    height: 3px;
  }
  
 
  .leftcontent::-webkit-scrollbar-track {
    background: #f2f2f2;
  }
  
 
  .leftcontent::-webkit-scrollbar-thumb {
    background-color: #FF8C04;
    border-radius: 5px;
  }
  
  
  .leftcontent::-webkit-scrollbar-thumb:hover {
    background-color: #FF8C04;
  }
  
  .rightContaint{
    overflow-x: hidden;
    overflow-y: scroll;
    height: 400px;
  }