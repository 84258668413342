@media (max-width: 575.98px) {
  .card-jobs {
    margin-top: 20px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .card-jobs {
    margin-top: 20px;
  }
}

@media (min-width: 992px) {
  .card-jobs {
    margin-top: 20px;
  }
}
