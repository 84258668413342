@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .company_container {
    min-width: 960px;
  }
}

@media (min-width: 992px) {
  .company_container {
    display: grid;
    grid-template-columns: 27% 70%;
    gap: 3%;
    width: 100%;
    max-width: 1307px;
    margin-inline: auto;
    padding: 2rem 0 0 0;
  }
  .company_sidebar {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 660px;

    background: #ffffff;
    border: 1px solid #d8eaf7;
    border-radius: 4px;
  }
  .company_sidebar > div {
    border-bottom: 1px solid #d8eaf7;
  }
  .company_sidebar__row_1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
  }
  .company_sidebar__row_1 > p {
    margin: 0;
    padding: 0;
  }
  .company_sidebar__row_1 > p:last-child {
    color: #246da2;
  }
  .company_sidebar__row_2 {
    height: 50%;
  }
  .company_sidebar__row_3 {
    height: 40%;
  }
  .company__main_content {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .control_row {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    padding: 0;
    position: sticky;
  }
  .control_row .control_button {
    width: max-content;
    min-width: 7rem;
    height: 2.5rem;
    padding: 0 1rem;
    font-weight: 400;
    letter-spacing: 0.0125rem;
    font-size: 0.85rem;
    white-space: nowrap;
    word-break: keep-all;
    border-radius: 4px;
    appearance: none;
    text-decoration: none;
    outline: none;
    border: none;
    line-height: 1.2;
    text-align: center;
    cursor: pointer;
    user-select: none;

    color: #246da2;
    background: #ffffff;
    border: 1px solid #d8eaf7;
    border-radius: 4px;
  }
  .control_button.active {
    background-color: #246da2;
    color: #ffffff;
  }
  .control_row > button:hover:enabled {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  }
  .control_row > button:active:enabled {
    transform: scale(0.97) !important;
  }
  .control_row > button:focus-visible {
    outline: 2px solid #9ea2b1 !important;
  }
  .control_row > button:disabled {
    background-color: #246da255 !important;
    cursor: not-allowed !important;
  }
  .company_search_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem;
    width: 100%;
    border: 1px solid #d8eaf7;
    border-radius: 4px;

    color: #246da2;
    background: #ffffff;
  }

  .company_search_bar > svg {
    width: 20px;
    height: 20px;
  }
  .company_search_bar input {
    width: 80%;
    padding: 0.5rem 0;

    border: none;
    outline: none;
    background: none;
  }
  .company_search_bar > span {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
  }
  .company_search_bar > span > p {
    margin: 0;
    padding: 0;
  }
  .company_search_bar > span > p:hover {
    margin: 0;
    padding: 0;
    text-decoration: underline;
  }
  .company_job_cards {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow-y: auto;
  }
  .job_card {
    display: grid;
    grid-template-rows: 4rem 1fr;
    align-items: center;
    width: 100%;
    min-height: auto;

    border-radius: 4px;
    cursor: pointer;

    background-color: #246da2;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  }
  .job_card__row_1 .job_card__quick_action_container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
    padding: 0 1rem;
    float: left;
    width: 70%;
  }
  .applied-label {
    float: left;
    margin-top: 10px;
    color: #ffffff;
    margin-left: -3rem;
  }
  .delete-button {
    float: right;
    margin-top: 10px;
    color: #ffffff;
    margin-right: 20px;
  }
  .applied-label i {
    margin-left: 8px;
  }
  .job_card .job_card__row_2 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 1rem;
    padding: 1rem;
    width: 100%;
    height: 100%;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fff;
  }
  .job_card .job_card__row_2 .job_card__description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0.8rem;

    font-size: 0.875rem;
    font-weight: 500;
  }
  .job_card .job_card__row_2 .job_card__description > div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1.5rem;
    width: 100%;
  }
  .job_card .job_card__row_2 .job_card__description > div > p {
    margin: 0;
    padding: 0;
  }
  .job_card__label_span {
    width: 10rem;
    word-wrap: break-word;
    color: #6f6f6f;
  }
  .job_card__values {
    color: #444444;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    gap: 0.5rem;
    flex-wrap: wrap;
    width: 78%;
  }
  .job_card__employer {
    margin: 0;
    padding: 0;
    color: #000;
    width: max-content;
    font-weight: 700;
  }
  .job_card__values > p {
    margin: 0;
    padding: 0;
    color: #6f6f6f;
    font-weight: 700;
  }

  .job_card__skill_values {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.75rem;
    flex-wrap: wrap;
    max-width: 44rem;
  }
  .job_card__skill {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
  }
  .job_card__skill > p {
    margin: 0;
    padding: 0;
    font-size: 0.875rem;
    font-weight: 600;
    color: #6f6f6f;
  }
  .job_card__skill svg {
    font-size: 1rem;
    color: #6f6f6f;
    cursor: pointer;
  }
  .st__star-rating {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    font-size: 0;
  }
  .st__star {
    display: inline-block;
    font-size: 18px;
    color: #d9d9d9;
    cursor: pointer;
  }
  .st__star.active {
    color: #ffa412;
  }
  .job_card_type {
    flex: auto;
  }
  .jobType {
    display: flex;
    gap: 100px;
  }
  .jobSalary {
    display: flex;
  }
  .company_job_cards-parent {
    height: 550px;
    display: flex;
    flex-direction: column;
  }
  .company_job_cards-parent-loader {
    height: auto;
  }
}
