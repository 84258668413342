.company_search_bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 0 1rem;
    width: 100%;
    border: 1px solid #d8eaf7;
    border-radius: 4px;

    color: #246da2;
    background: #ffffff;
}
.company_search_bar > svg {
    width: 20px;
    height: 20px;
}
.company_search_bar > input {
    width: 80%;
    padding: 0.5rem 0;

    border: none;
    outline: none;
    background: none;
}
