.company_layout {
    display: grid;
    grid-template-rows: 64px 1fr;
    width: 100%;
    height: 100vh;
  
    overflow-y: auto;

    position: relative;

    background-color: #eeeeee;
}
.company_layout > p{
    margin: 0;
    padding: 0;
}