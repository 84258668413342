@media (max-width: 575.98px) {
  .toggle-bar-new {
    position: relative;
    left: 1%;
  }
  .class_li {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #444444 !important;
  }
  .itemActive span {
    color: var(--list-item-color) !important;
  }
  .itemActive {
    border-radius: 2px;
    color: var(--list-item-color) !important;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .class_li a {
    text-decoration: none;
  }
  .class_li .nav-link {
    color: #444444;
  }
  .class_li .nav-link:hover {
    color: var(--list-item-color) !important;
    cursor: pointer;
  }
  .font-styling {
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: black;
    padding-top: 10px;
  }
  .font-styling:hover {
    color: var(--list-item-color);
  }
  .dropdown-menu {
    width: 10%;
    opacity: 0.8;
    border-radius: 7px 7px 0px 0px !important;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

@media (min-width: 992px) {
  .navBar-new-bg-color {
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
    padding-top: 18px;
    padding-bottom: 18px;
  }
  .navBar-button {
    width: 200px;
    height: 40px;
    border: 1px solid var(--list-item-color);
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: var(--list-item-color);
    box-shadow: 0px 4px 10px var(--list-box-shadow);
    border-radius: 4px;
    background: #ffffff;
  }
  .itemActive {
   
    border-radius: 2px;
    color: var(--list-item-color) !important;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* Add colored bottom */
    position: relative;
  }
  
  .itemActive::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    color: var(--list-item-color) !important;
    background-color: var(--list-item-color);
    border-radius: 1px 1px 0 0; 
  }
  
  .class_li a {
    text-decoration: none;
  }
  .class_li .nav-link {
    color: #444444;
  }
  .class_li .nav-link:hover {
  
    border-radius: 2px;
    color: var(--list-item-color) !important;
    cursor: pointer;
  }

  .font-styling {
    font-weight: 300;
    font-size: 20px;
    line-height: 23px;
    color: black;
    padding-top: 10px;
  }
  .font-styling:hover {
    background: linear-gradient(
      176.66deg,
      rgba(255, 255, 255, 0) 59.81%,
      var(--list-item-color) 99.03%
    ) !important;
    color: var(--list-item-color);
    border-radius: 2px;
  }
}
