@media (max-width: 575.98px) {
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .message-heading {
    padding-left: 20px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 2px solid #efebf9;
  }
  .message-heading span {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }

  .chat-list {
    height: 100vh;
    overflow-x: hidden !important;
  }
  .chat-box-heading {
    padding-left: 20px;
    padding-top: 6px;
    padding-bottom: 24px;
    border-bottom: 2px solid #efebf9;
  }
  .chat-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }
  .chat-subject {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }
  .message-window {
    padding-top: 20px;
    padding-bottom: 20px;
    height: 80vh;
    overflow-x: hidden !important;
  }
  .chat-input {
    padding-top: 20px;
  }
}

@media (min-width: 992px) {
  .candidate-chat-screen {
    background-color: #fdfdfd;
    margin-top: 20px;
    border: 1px solid #efebf9;
    border-radius: 8px, 8px, 0px, 0px;
    margin-bottom: 20px;
  }
  .message-heading-candidate {
    padding-left: 20px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 2px solid #efebf9;
  }
  .message-heading-candidate span {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
  }
  .message-search-candidate {
    margin-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #efebf9;
  }
  .chat-list-candidate {
    height: 58vh;
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .chat-list-candidate::-webkit-scrollbar {
    width: 7px;
  }
  .chat-list-candidate::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d8eaf7;
    border-radius: 10px;
  }
  .chat-list-candidate::-webkit-scrollbar-thumb {
    background: #aeaeae;
    border-radius: 10px;
  }
  .chat-box-heading-candidate {
    padding-left: 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 2px solid #efebf9;
  }
  .chat-name-candidate {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }
  .chat-subject-candidate {
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #444444;
  }
  .message-window-candidate {
    padding-top: 20px;
    padding-bottom: 20px;
    height: 50vh;
    overflow-x: hidden !important;
    overflow-y: auto;
  }
  .message-window-candidate::-webkit-scrollbar {
    width: 7px;
  }
  .message-window-candidate::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #d8eaf7;
    border-radius: 10px;
  }
  .message-window-candidate::-webkit-scrollbar-thumb {
    background: #aeaeae;
    border-radius: 10px;
  }
  .chat-input-candidate {
    padding-top: 20px;
  }
  .chat-image-candidate {
    text-align: center;
    margin-top: 120px;
  }
  .message-send-button-candidate {
    background: #246da2;
    color: #efebf9;
    transition: background 1s;
  }
  .message-send-button-candidate:hover {
    background: #2a5778;
    color: #efebf9;
  }
  .new-message-candidate {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    color: #6e4dcd;
    text-align: center;
    margin-top: 70px;
  }
  .welcome-content-candidate {
    margin-top: 20px;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: #6f6f6f;
    padding: 0 50px;
  }
  .chat-list-st {
    border: 1px solid #6e4dcd;
    border-width: 0px 5px 0px 5px;
  }
}
