/* .patch {
  width: 100%;
  height: 8vh;
  background-color:  #9B51E0;
}
.container-fluid {
  margin: 0;
  padding: 0;
}
.toggle-width-sidebar {
  width: 6%;
}
.toggle-width-dashboard {
  width: 94%;
} */

@media (max-width: 575.98px) {
  .hamburger-input-section {
    margin-top: 32px;
    margin-bottom: 40px;
    margin-left: 10px;
    margin-right: 10px;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
}

@media (min-width: 992px) {
  /* .hamburger-input-section {
    margin-top: 32px;
    margin-bottom: 40px;
    margin-left: 65px;
    margin-right: 65px;
  } */
}
