.pageNotfound {
  padding-top: 80px;
  padding-bottom: 80px;
}

@media (max-width: 480px) {
  .pageNotfound {
    padding-top: 95px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 95px;
  }
}
