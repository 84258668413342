.status:hover{

    color: blue;
    
}

@media (max-width:480px) {
    .searchIcon{
        display:none;
    }
}
