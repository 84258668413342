@media (max-width: 575.98px) {
  .referral-reward-section {
    width: 100%;
    height: 50px;
    background: var(--list-item-color);
    margin-bottom: 20px;
  }
  .referral-reward {
    margin-left: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    text-align: center;
  }
  .referral-reward span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
}

@media (min-width: 576px) and (max-width: 991.98px) {
  .referral-reward-section {
    width: 100%;
    height: 50px;
    background: var(--list-item-color);
  }
  .referral-reward {
    margin-left: 100px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .referral-reward span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
}

@media (min-width: 992px) {
  .referral-reward-section {
    width: 100%;
    height: 50px;
    background: var(--list-item-color);
  }
  .referral-reward {
    margin-left: 100px;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .referral-reward span {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
  }
}
