.recomFoot{
    margin-top: 35%;
  }
  
  .recommendationHeader{
    background-color: white;
  }

  .recommendationBody{

    background-color: white;

  }