.myTables {
  display: block;
  margin: 0 auto;
  margin-top: 60px;
}
.rdt_TableHeadRow {
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  background: #5a3eaa !important;
}
