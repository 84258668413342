.filter_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  width: 38rem;
  height: 33rem;

  background: #ffffff;
  border: 1px solid #d8eaf7;
  box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
.filter_container__row_1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--client-secondary-color);
}
.filter_container__skill_row {
  display: flex;
  flex-direction: column;
  width: 100%;

  height: 16rem;
  /*  min-height: 10rem;
    max-height: 16rem; */
  padding-bottom: 1rem;
  overflow-y: auto;

  border-bottom: 1px solid var(--client-secondary-color);
}
.filter_container__jobType_row_container {
  display: grid;
  grid-template-columns: 36rem 12rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--client-secondary-color);
}
.filter_container__exp_row_container {
  display: grid;
  grid-template-rows: 2rem 1fr;
  grid-template-columns: 12rem 12rem;
  gap: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--client-secondary-color);
}
.filter_container__exp_row_container p {
  margin: 0;
  padding: 0;
}
.filter_container__exp_row_container > span {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 0.875rem;
}
.filter_container__exp_row_minima {
  grid-column-start: 1;
  grid-column-end: 2;
}
.filter_container__btn_ctrls {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
