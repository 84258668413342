.modalBackground {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(51, 51, 51, 0.5);
    z-index: 200s;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 10;
}
